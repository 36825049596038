<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:57
-->
<template>
	<div class="recruitSearch">
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">活动名称</span>
				<el-input class="common-row-ipt" style="flex: 1;" placeholder="请输入" v-model="searchForm.activityName" />
			</div>
		</div>
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">招募ID</span>
				<el-input class="common-row-ipt" placeholder="请输入" v-model="searchForm.id" />
			</div>
		</div>
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">组织名称</span>
				<el-select class="common-row-select" v-model="searchForm.organizationId" placeholder="请选择">
					<el-option v-for="item in originList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="flex-item">
			<el-button @click="search" type="primary">查询</el-button>
			<el-button @click="reset">重置</el-button>
		</div>
		<div class="recruitSearch-table">
			<el-table :data="searchData" border>
				<el-table-column width="200px" prop="id" label="招募ID">
				</el-table-column>
				<el-table-column prop="activityName" label="活动名称">
				</el-table-column>
				<el-table-column width="300px" prop="recruitTimeList" label="活动时间">
					<template slot-scope="scope"
						v-if="scope.row.recruitTimeList&&scope.row.recruitTimeList.length > 0">
						<div class="table-action-date">
							<span v-for="(item, index) in scope.row.recruitTimeList"
								:key="index">{{`${dateFormat(item.starTime)} 至 ${dateFormat(item.endTime)}`}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="200px" prop="activityType" label="活动类型">
					<template slot-scope="scope">
						<span>{{scope.row.activityType == 1?"一般活动":"共建基地活动"}}</span>
					</template>
				</el-table-column>
				<el-table-column width="200px" prop="status" label="招募状态">
					<template slot-scope="scope">
						<span :class="'table-action-status'+ scope.row.status">{{scope.row.status == 0?"未开始":scope.row.status == 1?"进行中":"已完成"}}</span>
					</template>
				</el-table-column>
				<el-table-column width="200px" prop="action" label="操作">
					<template slot-scope="scope">
						<div class="table-action">
							<span @click="actionFn('detail', scope)">详情</span>
							<!-- <el-divider direction="vertical"></el-divider>
							<span @click="actionFn('inform', scope)">通知</span> -->
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		searchRecruit,
	} from "@/api/recruit";
	import {
		getDepartmentList,
		allOrigin,
	} from "@/api/system";
	export default {
		data() {
			return {
				searchForm: {},
				originList: [],
				searchData: []
			};
		},
		mounted() {
			this.getAllOriginList()
		},
		methods: {
			getAllOriginList() {
				allOrigin().then((res) => {
					if(res.code == 0) {
						this.originList = res.data.map(item =>{
							return {
								label: item.name,
								value: item.id
							}
						})
					}
				})
			},
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(":00", "")
				} else {
					return ""
				}
			},
			search() {
				this.getSearchRecruit()
			},
			reset() {
				this.searchForm = {}
				this.searchData = []
			},
			// 获取活动查询列表
			getSearchRecruit() {
				searchRecruit(this.searchForm).then((res) => {
					if (res.code == 0) {
						this.searchData = res.data
					}
				})
			},
			// 获取分会列表
			departmentList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
					type: "SUB_COMP"
				}
				this.originList = [];
				getDepartmentList(params).then((res) => {
					if (res.code == 0) {
						this.originList = res.data.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						})
					}
				})
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						this.$router.push({
							path: `/recruitList/detail?recruitId=${scope.row.id}`
						});
						break;
					case "inform":
						break;
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}
	
	/deep/.el-button {
		height: 36px;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}
	
	.table-action-date {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	
		span {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 20px;
			color: #303133;
		}
	}
	
	.table-action-status1 {
		color: #F5803E;
	}
	
	.table-action-status2 {
		color: #32AA5C;
	}

	.recruitSearch {
		padding: 20px 20px;

		.common-row {
			margin-bottom: 16px;

			.common-row-label {
				width: 100px;
			}

			.common-row-ipt {
				width: 520px;
			}

			.common-row-select {
				width: 520px;
			}
		}

		.recruitSearch-table {
			margin-top: 16px;
		}
	}
</style>
